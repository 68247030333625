
// Vue reactivity
import { reactive, defineComponent, watchEffect, ref, onMounted } from 'vue';

// icons
import { person, call, mail, close } from 'ionicons/icons';

// components
import { IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon, IonToolbar, IonModal,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea, IonGrid, IonCol, IonRow,
        IonHeader, IonDatetime,
        modalController, loadingController, } from '@ionic/vue';

// API services
import UserService from '@/services/UserService';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'UserProfileFormModal',
  props: {
    editingUser: null,
  },
  components: { IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon, IonToolbar, IonModal,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea, IonGrid, IonCol, IonRow,
                IonHeader, IonDatetime, },
  setup(props) {
    // methods or filters
    const { t } = useI18n();
    const { formatDate, presentToast, presentAlert, formatDateString } = utils();
    const dateInputModal = ref(null);

    // declare state variables (ref to make them reactive)
    const store = useStore();
    const user = reactive({
      name: "",
      email: "",
      birthdayYearMonth: null,
      yearOfBirth: null,
      monthOfBirth: null,
    });

    onMounted(() => {
      const { name, email, yearOfBirth, monthOfBirth, } = props.editingUser.value;
      user.name = name;
      user.email = email;
      if (yearOfBirth && monthOfBirth) {
        user.birthdayYearMonth = `${yearOfBirth}-${String(monthOfBirth).padStart(2, '0')}`;
        console.log(user.birthdayYearMonth);
      }
    });

    const closeModal = async (updatedUser: any = null) => {
      await modalController.dismiss({ updatedUser });
    };
    const updateUser = async() => {
      const loading = await loadingController.create({});
      await loading.present();
      try {
        if (user.birthdayYearMonth) {
          const [yearOfBirth, monthOfBirth] = user.birthdayYearMonth.split("-");
          user.yearOfBirth = yearOfBirth;
          user.monthOfBirth = monthOfBirth;
        }
        console.log(user);
        UserService.updateLoggedInUser(user, props.editingUser.value);
        store.commit('updateUser', user);
        presentToast(t('successUpdatePersonalInfo'), 2000);
        closeModal();
      } catch (e) {
        presentAlert(t('clashEmailAddress'));
      }
      loading.dismiss();
    }

    // 3. return variables & methods to be used in template HTML
    return {
      t,
      
      // icons
      mail, person, call, close,

      // variables
      user, dateInputModal,

      // methods
      formatDate, closeModal, updateUser,
      saveDateOfBirth: () => {
        setTimeout(() => {
          user.birthdayYearMonth = formatDateString(user.birthdayYearMonth, 'YYYY-MM');
          dateInputModal.value.$el.dismiss();
        }, 500);
      },
    }
  }
});
