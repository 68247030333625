import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "datetime-modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      showCloseModal: true,
      title: _ctx.t('UserProfilePage.editPersonalInfo')
    }, null, 8, ["title"]),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('UserProfilePage.name')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.user.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.name) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('UserProfilePage.emailAddress')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  inputmode: "email",
                  type: "text",
                  modelValue: _ctx.user.email,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, {
              id: "open-user-birth-date-input",
              button: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('birthdayYearMonth')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  readonly: "",
                  modelValue: _ctx.user.birthdayYearMonth,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.birthdayYearMonth) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_modal, {
              ref: "dateInputModal",
              trigger: "open-user-birth-date-input",
              "show-backdrop": true,
              "keep-contents-mounted": true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_toolbar, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_buttons, { slot: "end" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              slot: "icon-only",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dateInputModal.$el.dismiss()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_content, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_ion_datetime, {
                        mode: "ios",
                        modelValue: _ctx.user.birthdayYearMonth,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.birthdayYearMonth) = $event)),
                        presentation: "month-year"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_ion_button, {
                        expand: "block",
                        style: {"max-width":"480px","margin":"0 auto"},
                        onClick: _ctx.saveDateOfBirth
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('confirm')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              expand: "block",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateUser()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('UserProfilePage.save')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}