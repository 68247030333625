import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bada91b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spin"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { style: {"padding-right":"16px"} }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_order_item = _resolveComponent("order-item")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        title: $setup.t('profile'),
        parentPath: "/home"
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          ($setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner)
              ]))
            : (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                class: "ion-text-center",
                fixed: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-padding-top" }, {
                    default: _withCtx(() => [
                      ($setup.userLoggedIn)
                        ? (_openBlock(), _createBlock(_component_ion_card, {
                            key: 0,
                            color: "tertiary",
                            style: {"width":"100%"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, {
                                    style: {"padding":"16px 0 16px 16px"},
                                    size: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      ($setup.userLoggedIn)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                            _createElementVNode("img", {
                                              class: "user-image",
                                              src: $setup.user.profilePic
                                            }, null, 8, _hoisted_3),
                                            _createVNode(_component_ion_button, {
                                              class: "ion-margin-top",
                                              color: "light",
                                              size: "small",
                                              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateProfilePic()))
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, {
                                                  slot: "start",
                                                  icon: $setup.camera
                                                }, null, 8, ["icon"]),
                                                _createTextVNode(" " + _toDisplayString($setup.t('UserProfilePage.changeProfilePhoto')), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                            _createElementVNode("img", {
                                              class: "user-image",
                                              src: require('@/assets/avatar.svg')
                                            }, null, 8, _hoisted_5)
                                          ]))
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_col, { style: {"display":"flex","align-self":"center","flex-direction":"column"} }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h3", null, _toDisplayString($setup.user.name), 1),
                                      _createElementVNode("h3", null, _toDisplayString($setup.t('myPoints')) + ": " + _toDisplayString($setup.user.availablePoints), 1),
                                      _createElementVNode("p", null, [
                                        _createVNode(_component_ion_button, { id: "open-point-history-modal" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString($setup.t('pointHistory')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_modal, { trigger: "open-point-history-modal" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_page_header, {
                            showCloseModal: true,
                            title: $setup.t('pointHistory')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_text, {
                                color: "tertiary",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_6, [
                                    _createElementVNode("b", null, _toDisplayString($setup.t('remaining')) + ": " + _toDisplayString($setup.user.availablePoints), 1)
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["title"]),
                          _createVNode(_component_ion_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_grid, { fixed: "" }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.user.userPointRecords, (r) => {
                                    return (_openBlock(), _createBlock(_component_ion_item, {
                                      key: r.id
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, null, {
                                          default: _withCtx(() => [
                                            _createElementVNode("h2", null, _toDisplayString(r.event), 1),
                                            _createElementVNode("p", null, _toDisplayString($setup.formatDateTime(r.createdAt)), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        (r.adjustment >= 0)
                                          ? (_openBlock(), _createBlock(_component_ion_text, {
                                              key: 0,
                                              slot: "end",
                                              color: "success"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("+" + _toDisplayString(r.adjustment), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : (_openBlock(), _createBlock(_component_ion_text, {
                                              key: 1,
                                              slot: "end",
                                              color: "danger"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(r.adjustment), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          ($setup.userLoggedIn)
                            ? (_openBlock(), _createBlock(_component_ion_card_header, { key: 0 }, {
                                default: _withCtx(() => [
                                  ($setup.userOrders.length > 0)
                                    ? (_openBlock(), _createBlock(_component_ion_card, {
                                        key: 0,
                                        class: "list-card"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_card_header, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_card_subtitle, { class: "ion-text-center" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString($setup.t('myOrders')), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userOrders.slice(0, 5), (order) => {
                                            return (_openBlock(), _createBlock(_component_order_item, {
                                              key: order.id,
                                              order: order
                                            }, null, 8, ["order"]))
                                          }), 128)),
                                          _withDirectives(_createVNode(_component_ion_item, {
                                            lines: "none",
                                            "router-link": "/orders",
                                            button: "",
                                            detail: ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                slot: "start",
                                                icon: $setup.receiptOutline
                                              }, null, 8, ["icon"]),
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString($setup.t('UserProfilePage.browseAllOrders')), 1)
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }, 512), [
                                            [_vShow, $setup.userOrders.length > 5]
                                          ])
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_ion_card, { class: "list-card" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_header, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_card_subtitle, { class: "ion-text-center" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString($setup.t('myPets')), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_list, null, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userPets, (up) => {
                                            return (_openBlock(), _createBlock(_component_ion_item, {
                                              lines: "full",
                                              key: up.id,
                                              onClick: ($event: any) => ($setup.openPetFormModal(up)),
                                              button: "",
                                              detail: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("img", {
                                                      style: {"object-fit":"contain"},
                                                      src: up.photoLink
                                                    }, null, 8, _hoisted_7)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_ion_label, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("h3", null, _toDisplayString(up.name), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          }), 128)),
                                          _createVNode(_component_ion_item, {
                                            lines: "none",
                                            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.openPetFormModal())),
                                            button: "",
                                            detail: "false"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("h3", null, _toDisplayString($setup.t('addPet')), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_ion_icon, {
                                                size: "small",
                                                slot: "start",
                                                icon: $setup.add
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_card_content, null, {
                            default: _withCtx(() => [
                              ($setup.userLoggedIn)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 0,
                                    class: "ion-margin-bottom",
                                    expand: "block",
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.openEditUserProfileModal()))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        slot: "start",
                                        icon: $setup.createOutline
                                      }, null, 8, ["icon"]),
                                      _createTextVNode(" " + _toDisplayString($setup.t('UserProfilePage.editPersonalInfo')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              ($setup.userLoggedIn)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 1,
                                    expand: "block",
                                    onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.presentChangePasswordPrompt()))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        slot: "start",
                                        icon: $setup.keyOutline
                                      }, null, 8, ["icon"]),
                                      _createTextVNode(" " + _toDisplayString($setup.t('UserProfilePage.changePassword')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_ion_list, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, {
                                    lines: "full",
                                    "router-link": "/payment-methods",
                                    button: "",
                                    detail: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        slot: "start",
                                        icon: $setup.cardOutline
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('myPaymentMethods')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, {
                                    lines: "full",
                                    "router-link": "/liked-products",
                                    button: "",
                                    detail: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        slot: "start",
                                        icon: $setup.heartOutline
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('likedProducts')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, {
                                    lines: "full",
                                    "router-link": "/liked-places",
                                    button: "",
                                    detail: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        slot: "start",
                                        icon: $setup.locationOutline
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('likedPlaces')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, {
                                    lines: "full",
                                    "router-link": "/liked-infos",
                                    button: "",
                                    detail: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        slot: "start",
                                        icon: $setup.newspaperOutline
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('likedInfos')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        slot: "start",
                                        icon: $setup.globe
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_select, {
                                        label: $setup.t('UserProfilePage.language'),
                                        interface: "popover",
                                        modelValue: $setup.locale,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.locale) = $event))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_select_option, { value: "zh" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("中文")
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_select_option, { value: "en" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("English")
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["label", "modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                fill: "clear",
                                size: "small",
                                "router-link": "/terms-and-conditions"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('UserProfilePage.termsAndConditions')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                fill: "clear",
                                size: "small",
                                "router-link": "/feedback"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('UserProfilePage.feedback')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createElementVNode("p", null, [
                                _createVNode(_component_ion_note, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Ver. " + _toDisplayString($setup.versionCode), 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, {
                    class: "ion-justify-content-center",
                    style: {"margin-bottom":"75px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { size: "12" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            ($setup.userLoggedIn)
                              ? (_openBlock(), _createBlock(_component_ion_chip, {
                                  key: 0,
                                  outline: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, { icon: $setup.phonePortraitOutline }, null, 8, ["icon"]),
                                    _createElementVNode("span", null, "(" + _toDisplayString($setup.user.dialCode) + ") " + _toDisplayString($setup.user.phone) + " ", 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          ($setup.userLoggedIn)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 0,
                                color: "medium",
                                class: "logout-btn",
                                onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.doLogout()))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "end",
                                    icon: $setup.logOutOutline
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString($setup.t('UserProfilePage.logout')), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_ion_button, {
                                key: 1,
                                color: "primary",
                                onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.openLoginModal()))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "end",
                                    icon: $setup.logInOutline
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString($setup.t('loginOrRegister')), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      }),
                      ($setup.userLoggedIn && $setup.user.roles == 'admin')
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            class: "ion-margin-top",
                            size: "12"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                fill: "clear",
                                color: "danger",
                                size: "small",
                                onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.deleteAccount()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.t('deleteAccount')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}