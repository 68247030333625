
// Vue
import { computed, watch } from 'vue';
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { SplashScreen } from "@capacitor/splash-screen";

// icons
import { logInOutline, logOutOutline, createOutline, keyOutline, camera, globe, moon, add, close, phonePortraitOutline, 
          locationOutline, newspaperOutline, heartOutline, receipt, receiptOutline, cardOutline, } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonSpinner, IonList, IonListHeader, IonItem, IonLabel,
        IonIcon, IonButtons, IonBackButton, IonButton, IonNote, IonImg, IonModal,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonThumbnail,
        IonToggle, IonSelect, IonSelectOption, IonBadge, IonChip, IonFabButton,
        alertController, modalController, loadingController, } from '@ionic/vue';
import UserProfileFormModal from '@/components/modals/UserProfileFormModal.vue';
import EditPhoneModal from '@/components/auth/EditPhoneModal.vue';
import PetFormModal from '@/components/modals/PetFormModal.vue';
import OrderItem from "@/components/order/OrderItem.vue";

// API services
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';

// utils or methods
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import config from '@/config';
import { utils } from '@/composables/utils';

export default {
  name: 'UserProfile',
  components: { IonPage, IonContent, IonGrid, IonRow, IonCol, IonSpinner, IonList, IonListHeader, IonItem, IonLabel,
                IonIcon, IonButtons, IonBackButton, IonButton, IonNote, IonImg, IonModal,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonThumbnail,
                IonToggle, IonSelect, IonSelectOption, IonBadge, IonChip, IonFabButton,
                OrderItem, },
  setup() {
    const store = useStore();
    
    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppUserData);
    const user = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const userPets = computed(() => store.state.userPets);
    const userOrders = computed(() => store.state.userOrders);
    
    // methods or filters
    const { t, locale } = useI18n();
    const { presentToast, openModal, openLoginModal, presentPrompt, formatDateTime } = utils();

    const doLogout = () => {
      presentPrompt(t('UserProfilePage.logout'), t('UserProfilePage.confirmLogout'), async () => {
        await AuthService.doLogout();
        SplashScreen.show();
        window.location.reload();
      });
    }
    const presentChangePasswordPrompt = async () => {
      const alert = await alertController.create({
        header: t('UserProfilePage.changePassword'),
        inputs: [
          {
            name: 'oldPassword',
            type: 'password',
            placeholder: t('UserProfilePage.oldPassword')
          },
          {
            name: 'newPassword',
            type: 'password',
            placeholder: t('UserProfilePage.newPassword')
          },
          {
            name: 'newPasswordConfirm',
            type: 'password',
            placeholder: t('UserProfilePage.newPasswordConfirm')
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('confirm'),
            handler: (value) => {
              if (value.oldPassword && value.newPassword && value.newPasswordConfirm) {
                if (value.newPassword != value.newPasswordConfirm) {
                  presentToast(t('inconsistentNewPasswordMsg'), 5000, 'top');
                } else {
                  AuthService.updatePassword(value.oldPassword, value.newPassword).then(res => {
                    if (res == 'wrong-password') {
                      presentToast(t('wrongOldPassword'), 5000, 'top');
                    } else {
                      presentToast(t('successUpdatePassword'), 3000, 'top');
                      alert.dismiss();
                    }
                  });
                }
              } else {
                presentToast(t('enterAllFields'), 5000, 'top');
              }
              return false;
            }
          }
        ]
      });
      await alert.present();
    }

    // update user phone number (need SMS verification)
    const openEditPhoneModal = async () => {
      const modal = await modalController.create({
        component: EditPhoneModal,
        componentProps: { editingUser: user },
        cssClass: 'small-modal',
      });
      return modal.present();
    }

    // update user information like first name
    const openEditUserProfileModal = async () => {
      const modal = await modalController.create({
        component: UserProfileFormModal,
        componentProps: { editingUser: user },
      });
      return modal.present();
    }

    // Update user profile picture (Capacitor Camera)
    const updateProfilePic = async () => {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        quality: 60,
        width: 1500,
        promptLabelHeader: t('photo'),
        promptLabelCancel: t('cancel'),
        promptLabelPhoto: t('fromPhotos'),
        promptLabelPicture: t('takePicture'),
      });
      const loading = await loadingController.create({});
      await loading.present();
      const fileName = `${moment().format('YYYYMMDDHHmmss')}-${user.value.username}.${cameraPhoto.format}`;
      UserService.updateLoggedInUserProfilePic(cameraPhoto.dataUrl, fileName, user.value.profilePic);
      store.commit('updateUser', { profilePic: cameraPhoto.dataUrl });
      loading.dismiss();
      presentToast(t("UserProfilePage.successChangeProfilePhoto"), 3000, 'top');
    }

    const toggleDarkTheme = async (enableDarkTheme: boolean) => {
      if (user.value.id) { // only for logged in users
        const currDark = user.value.darkTheme;
        if ((currDark && !enableDarkTheme) || (!currDark && enableDarkTheme)) {
          const loading = await loadingController.create({});
          await loading.present();
          UserService.updateUserDarkThemeSetting(enableDarkTheme);
          document.body.classList.toggle('dark', enableDarkTheme);
          loading.dismiss();
        }
      } else {
        document.body.classList.toggle('dark', enableDarkTheme);
      }
    }
    watch(locale, (currLocale: any) => {
      if (user.value.id) { // only for logged in users
        UserService.updateUserAppLanguage(currLocale);
      }
    });

    const openPetFormModal = async (editingUserPet: any = null) => {
      return await openModal(PetFormModal, { editingUserPet });
    }

    const deleteAccount = async () => {
      presentPrompt(t('deleteAccount'), t('confirmDeleteAccountMsg'), async () => {
        const loading = await loadingController.create({});
        await loading.present();
        await UserService.deleteLoggedInUser();
        loading.dismiss();

        AuthService.doLogout();
        presentToast(t('accountDeleted'), 3000);
      });
    }
    
    // 3. return variables & methods to be used in template HTML
    return {
      t, locale,

      // icons
      logInOutline, logOutOutline, createOutline, keyOutline, camera, globe, moon, add, close, phonePortraitOutline,
      locationOutline, newspaperOutline, heartOutline, receipt, receiptOutline,
      cardOutline,

      // variables
      userOrders,
      loading, user, userLoggedIn, userPets,
      versionCode: config.versionCode,

      // methods
      toggleDarkTheme, doLogout, presentChangePasswordPrompt,
      openEditPhoneModal, openEditUserProfileModal, updateProfilePic,
      openPetFormModal, deleteAccount,
      openLoginModal,
      formatDateTime,
    }
  },
}
