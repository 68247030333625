
// Vue reactivity
import { defineComponent, onMounted, ref, watchEffect } from 'vue';

// icons
import { person, call, mail, close, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonInput, IonGrid, IonCol, IonRow, IonText,
        modalController, loadingController, toastController, alertController, 
        isPlatform, } from '@ionic/vue';

// API services
import AuthService from '@/services/AuthService';
import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
import { auth, credentialSignIn, phoneSignIn, updateUserPhoneNumber, reauthUserWithCredential } from '@/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import UserService from '@/services/UserService';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'EditPhoneModal',
  props: {
    editingUser: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonInput, IonText,
                IonGrid, IonCol, IonRow, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const store = useStore();

    const phone = ref("");
    const isInvalidPhone = ref(false);
    const isEditingPhone = ref(false);

    if (props.editingUser) { // editing phone
      isEditingPhone.value = true;
      watchEffect(() => {
        phone.value = props.editingUser.value.phone;
      })
    }

    const window: any = {
      recaptchaVerifier: undefined,
      confirmationResult: undefined,
      verificationId: undefined,
    };
    onMounted(() => {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('grecaptcha-div', {
        'size': 'invisible',
        'callback': (res: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    });
    // methods or filters
    const { t } = useI18n();
    const { presentAlert, iPhoneNativeApp } = utils();

    const closeModal = async () => {
      window.recaptchaVerifier.clear();
      document.querySelector('#recaptcha-container').innerHTML = '<div id="grecaptcha-div"></div>';
      await modalController.dismiss({});
    };
    const verifyCode = async (verificationCode: any, alert: any = null) => {
      const loading = await loadingController.create({});
      await loading.present();
      try {
        if (isEditingPhone.value == true) {
          const phoneCredential = firebase.auth.PhoneAuthProvider.credential(window.verificationId, verificationCode);
          await updateUserPhoneNumber(auth.currentUser, phoneCredential);
          await UserService.updateLoggedInUser({ ...props.editingUser.value, phone: phone.value }, props.editingUser.value);
          store.commit('updateUser', { phone: phone.value });
          reauthUserWithCredential(auth.currentUser, phoneCredential);
        } else {
          if (iPhoneNativeApp()) {
            const credential = firebase.auth.PhoneAuthProvider.credential(window.verificationId, verificationCode);
            await credentialSignIn(credential);
          } else {
            await window.confirmationResult.confirm(verificationCode);
          }
        }
        loading.dismiss();
        if (alert) alert.dismiss();
        closeModal();
      } catch (e) {
        loading.dismiss();
        if (e.code == "auth/invalid-verification-code") { // wrong verification code
          presentAlert(t('RegisterPage.invalidVerificationCode'))
        } else {
          presentAlert(e.message);
        }
      }
    }
    const presentCodeInputAlert = async (phone: any) => {
      loadingController.dismiss();
      const alert = await alertController.create({
        backdropDismiss: false,
        header: t('RegisterPage.verifyingMobileNumber'),
        subHeader: `${t('RegisterPage.verifyMobileNumberInstruction')} (${phone}).`,
        inputs: [
          {
            name: 'verificationCode',
            type: 'text',
            placeholder: t('RegisterPage.verificationCode'),
            attributes: {
              inputmode: 'numeric',
              maxlength: '6',
            }
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('RegisterPage.verify'),
            handler: (value) => {
              if (value.verificationCode) {
                verifyCode(value.verificationCode, alert);
              }
              return false; // not closing the alert
            },
          },
        ],
      });
      await alert.present();
    }
    const signInWithPhoneNumber = async (verifyingPhone) => {
      return new Promise(async (resolve) => {
        // Attach `phoneCodeSent` listener to be notified as soon as the SMS is sent
        await FirebaseAuthentication.addListener('phoneCodeSent', async (event) => {
          resolve(event.verificationId);
        });
        // Start sign in with phone number and send the SMS
        await FirebaseAuthentication.signInWithPhoneNumber({ phoneNumber: verifyingPhone });
      });
    };
    const doSMSLogin = async (phone: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = isEditingPhone.value || await AuthService.checkPhoneExists(phone);
      if (res === true) {
        try {
          // verify the phone
          const appVerifier = window.recaptchaVerifier;
          const verifyingPhone = `+852${phone}`;
          if (iPhoneNativeApp()) {
            const verificationId = await signInWithPhoneNumber(verifyingPhone);
            //const { verificationId } = await FirebaseAuthentication.signInWithPhoneNumber({ phoneNumber: verifyingPhone });
            loading.dismiss();
            window.verificationId = verificationId;
            presentCodeInputAlert(phone);
          } else {
            if (isEditingPhone.value == true) {
              const provider = new firebase.auth.PhoneAuthProvider();
              window.verificationId = await provider.verifyPhoneNumber(verifyingPhone, appVerifier);
            } else {
              window.confirmationResult = await phoneSignIn(verifyingPhone, appVerifier);
            }
            presentCodeInputAlert(phone);
          }
        } catch (e) {
          loadingController.dismiss();
          if (e.code == "auth/invalid-phone-number") {
            presentAlert(t('RegisterPage.enterValidHKMobileNumber')); // invalid phone number
          } else {
            presentAlert(e.message);
          }
        }
      } else {
        loadingController.dismiss();
        isInvalidPhone.value = true;
      }
    }

    // 3. return variables & methods to be used in template HTML
    return {
      t,
      
      // icons
      mail, person, call, close,

      // variables
      phone, isInvalidPhone, isEditingPhone,

      // methods
      doSMSLogin, closeModal,
    }
  }
});
